<template>
    <div class="login-box">
        <div class="login-main">
            <div>
                <div class="login-form-box">
                    <div class="ant-taba ant-tabs-top ant-tabs-line">
                        <div class="ant-tabs-bar">
                            <div style="font-size: 16px; text-align: center;margin-bottom: 10px;">管理登录</div>
                        </div>
                        <div class="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content">
                            <div class="ant-tabs-tabpane ant-tabs-tabpane-active">
                                <div class="login-input-item flex alcenter space">
                                    <span class="mobile ant-input-affix-wrapper">
                                        <span class="ant-input-prefix">账号</span>
                                        <input type="text" class="ant-input" v-model="data.mobile">
                                    </span>
                                </div>
                                <div class="login-input-item flex alcenter space mt24">
                                    <span class="mobile ant-input-affix-wrapper">
                                        <span class="ant-input-prefix">密码</span>
                                        <input type="password" class="ant-input" v-model="data.pwd">
                                    </span>
                                </div>
                                <div class="mt32 login-btn">
                                    <button class="ant-btn ant-btn-primary" style="width: 100%;" @click="login()">登录</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { apiLogin } from '../utils/js/api.js'
	export default{
		
		data(){
			return {
				data:{
                    mobile:'',
                    pwd:''
                },
			}
		},
		created(){
		},
		methods:{
			login(){
				apiLogin(this.data).then(res=>{
                    // console.log('res',res)
                    localStorage.setItem('xhmmp-account-token',res.auth_token);
					this.$router.push('/');
				}).catch(res=>{
					// console.log(res);
				});
			},
		}
	}
</script>


<style>
    .login-box, .login-main{
        width: 100vw;
        height: 100vh;
    }
    .login-box{
        background: linear-gradient(135deg,#4772ff,#5851ff);
        position: relative;
    }
    .login-form-box{
        width: 420px;
        height: 362px;
        background: #fff;
        box-shadow: 0 4px 32px -8px rgba(0,0,0,.02);
        border-radius: 24px;
        margin-top: 36px;
        padding: 48px 40px;
    }
    .login-box {
        background: linear-gradient(135deg,#4772ff,#5851ff);
        position: relative
    }

    .login-box,.login-main {
        width: 100vw;
        height: 100vh
    }

    .login-main {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2
    }

    .login-box-cover-img {
        position: absolute;
        bottom: 0;
        width: 100vw
    }

    .login-form-box {
        width: 420px;
        height: 362px;
        background: #fff;
        box-shadow: 0 4px 32px -8px rgba(0,0,0,.02);
        border-radius: 24px;
        margin-top: 36px;
        padding: 48px 40px
    }

    .login-input-item {
        width: 100%;
        height: 50px;
        border-radius: 30px;
        border: 1px solid #dcdfe6;
        padding: 0 24px
    }

    .login-input-item .ant-input {
        border: none;
        font-size: 16px
    }

    .login-input-item .ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 66px
    }

    .login-input-item .mobile {
        width: 100%
    }

    .login-input-item .code {
        width: 200px
    }

    .login-input-item .ant-input-affix-wrapper .ant-input-prefix {
        color: #a6aec2;
        font-size: 16px
    }

    .login-input-item .ant-input:focus {
        border: none;
        box-shadow: none
    }

    .login-btn .ant-btn {
        height: 50px;
        border-radius: 25px;
        font-size: 16px
    }
</style>